export const social = [
  "facebook",
  "google",
  "instagram",
  "messenger",
  "paper-plane",
  "speech-bubble",
  "tiktok",
  "user",
  "youtube",
  "building",
  "email",
]
