<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
  <span class="relative">
    <img class="w-7 h-7 rounded-full" *ngIf="showprofilePicture && user?.profilePicture" [src]="user?.profilePicture">
    <mat-icon *ngIf="!showprofilePicture || !user?.profilePicture"
      [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    <span class="absolute right-0 bottom-0 w-2 h-2 rounded-full"
      [ngClass]="{'mr-px mb-px': !showprofilePicture || !user?.profilePicture}"></span>
  </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
  <button mat-menu-item [routerLink]="['/users/settings']">
    <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    <span>{{ 'settings' | transloco }}</span>
  </button>
  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item (click)="signOut()">
    <mat-icon [svgIcon]="'mat_outline:logout'"></mat-icon>
    <span>{{ 'logout' | transloco }}</span>
  </button>
</mat-menu>
