import { Injectable } from '@angular/core';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { AuthClient, AuthResponse, LoginCommand, ResetPasswordCommand, SendResetPasswordTokenCommand, ValidateResetTokenCommand } from '../../../../api-client';

@Injectable()
export class AuthService {
  private _authenticated: boolean = false;

  constructor(
    private readonly authClient: AuthClient,
    private readonly _userService: UserService
  ) {
  }

  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  forgotPassword(email: string): Observable<any> {
    return this.authClient.sendResetPasswordToken(<SendResetPasswordTokenCommand>{
      email: email
    });
  }

  resetPassword(email: string, password: string, token: string): Observable<any> {
    return this.authClient.resetPassword(<ResetPasswordCommand>{
      email: email, password: password, token: token
    });
  }

  validateToken(email: string, token: string): Observable<any> {
    return this.authClient.validateResetToken(<ValidateResetTokenCommand>{
      email: email,
      token: token
    })
  }

  signIn(credentials: { email: string; password: string }): Observable<AuthResponse> {
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    return this.authClient.login(<LoginCommand>{
      login: credentials.email,
      password: credentials.password
    }).pipe(
      switchMap((response: AuthResponse) => {
        this.accessToken = response.token;
        this._authenticated = true;
        this._userService.user = response.user;

        return of(response);
      })
    );
  }

  signOut(): Observable<any> {
    localStorage.removeItem('accessToken');
    this._authenticated = false;
    return of(true);
  }

  check(): Observable<boolean> {
    if (this._authenticated) {
      return of(true);
    }

    if (!this.accessToken) {
      return of(false);
    }

    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    return of(true);
  }
}
