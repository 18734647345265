import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FileHandle } from 'app/shared/diretives/drag-and-drop.directive';

@Component({
    selector: 'app-video-uploader',
    templateUrl: './video-uploader.component.html',
    styleUrls: ['./video-uploader.component.scss']
})
export class VideoUploaderComponent implements OnInit, AfterViewInit {
    @Output() fileSelected: EventEmitter<File> = new EventEmitter<File>;
    @Input() defaultVideo: string;
    @Input() uploadProgress: number | undefined;

    file: FileHandle;

    constructor(private sanitizer: DomSanitizer) { }
    ngAfterViewInit(): void {
        if (this.defaultVideo) {
            //@ts-ignore
            window.pandascripttag = window.pandascripttag || [];


            //@ts-ignore
            window.pandascripttag.push(function () {
                //@ts-ignore
                const player = new PandaPlayer(`panda`, {
                    onReady: () => {
                    },
                    onError: (error) => {

                    },
                    video_id: this.defaultVideo
                })

            })
        }
    }

    ngOnInit(): void {
        if (this.defaultVideo) {
            this.file = <FileHandle>{
                file: null,
                url: this.defaultVideo
            }
        }
    }

    files(files: FileHandle[]) {
        if (files.length > 0 && files[0].file.type.includes('video')) {
            this.file = files[0];

            this.fileSelected.emit(this.file.file)
        }
    }

    doFileInput(event) {
        if (event.target.files.length > 0 && event.target.files[0].type.includes('video')) {
            const file = event.target.files[0];
            const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));

            const filesizebytes = file.size;
            const filesizemb = file.size / (1024 * 1024);

            this.file = <FileHandle>{
                file: file,
                url: url,
            }



            this.fileSelected.emit(this.file.file)
        }
    }
}
