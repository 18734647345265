export const filter = (d: Date | null) => {
    const now = new Date(Date.now());
    const date = new Date(d)


    date.setHours(0, 0, 0, 0);
    now.setHours(0, 0, 0, 0);

    return date >= now;
}

export const reversefilter = (d: Date | null) => {
    const now = new Date(Date.now());
    const date = new Date(d)


    date.setHours(0, 0, 0, 0);
    now.setHours(0, 0, 0, 0);

    return date <= now;
}
