<div class="w-full">
  <div class="flex flex-col gap-y-4 w-full max-h-full">
    <div class="w-full flex flex-col gap-4">
      <form [formGroup]="form">
        <div class="grid grid-cols-1 gap-x-4 gap-y-1 sm:grid-cols-12">
          <div class="sm:col-span-8">
            <mat-form-field class="w-full fuse-mat-dense">
              <mat-label>{{ 'contract_model' | transloco }} </mat-label>
              <mat-select [value]="1" formControlName="contractModelId">
                <ng-container *ngFor="let model of models">
                  <mat-option [value]="model.id">
                    {{model.name}}
                  </mat-option>
                </ng-container>
              </mat-select>
              <mat-error *ngIf="form.get('contractModelId')?.hasError('required')">
                {{ 'contract_model_required' | transloco }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="sm:col-span-4">
            <mat-form-field class="w-full fuse-mat-dense">
              <mat-label>{{ 'start_class' | transloco }} </mat-label>
              <input matInput [matDatepicker]="picker" formControlName="startDate" [matDatepickerFilter]="filter">
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error *ngIf="form.get('startDate')?.hasError('required')">
                {{ 'start_class_required' | transloco }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="sm:col-span-4">
            <mat-form-field class="w-full fuse-mat-dense">
              <mat-label>{{ 'rescheduling_limit' | transloco }}</mat-label>
              <input matInput formControlName="reschedulingLimit" type="number" min="0">
              <mat-error *ngIf="form.get('reschedulingLimit')?.hasError('required')">
                {{ 'rescheduling_limit_required' | transloco }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="sm:col-span-4">
            <mat-form-field class="w-full fuse-mat-dense">
              <mat-label>{{ 'currency' | transloco }} </mat-label>
              <mat-select [value]="1" formControlName="currencyId" (selectionChange)="onCurrencySelected($event)">
                <ng-container *ngFor="let currency of currencies">
                  <mat-option [value]="currency.id">
                    <mat-icon class="icon-size-4"
                      [svgIcon]="'flags:' + currency.countryAlpha2Code.toLowerCase()"></mat-icon>
                    {{currency.name}} - {{currency.code}}
                  </mat-option>
                </ng-container>
              </mat-select>
              <mat-error *ngIf="form.get('startDate')?.hasError('required')">
                {{ 'currencyId_required' | transloco }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="sm:col-span-4">
            <mat-form-field class="w-full fuse-mat-dense">
              <mat-label>{{ 'payment_type' | transloco }}</mat-label>
              <mat-select formControlName="paymentType">
                <mat-option [value]="paymentType.BankTransfer">{{ 'bank_transfer' | transloco }}</mat-option>
                <mat-option [value]="paymentType.CreditCard">{{ 'credit_card' | transloco }}</mat-option>
                <mat-option [value]="paymentType.Boleto">{{ 'boleto' | transloco }}</mat-option>
                <mat-option [value]="paymentType.PIX">{{ 'pix' | transloco }}</mat-option>
                <mat-option [value]="paymentType.PayPal">{{ 'paypal' | transloco }}</mat-option>
                <mat-option [value]="paymentType.MercadoLivre">{{ 'mercado_livre' | transloco }}</mat-option>
                <mat-option [value]="paymentType.Zelle">{{ 'zelle' | transloco }}</mat-option>
                <mat-option [value]="paymentType.Venmo">{{ 'venmo' | transloco }}</mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('paymentType')?.hasError('required')">
                {{ 'payment_type_required' | transloco }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="sm:col-span-6">
            <mat-form-field class="w-full fuse-mat-dense">
              <mat-label>{{ 'first_invoice_date' | transloco }} </mat-label>
              <input matInput [matDatepicker]="firstPicker" formControlName="firstPaymentDate"
                [matDatepickerFilter]="filter">
              <mat-datepicker-toggle matIconSuffix [for]="firstPicker"></mat-datepicker-toggle>
              <mat-datepicker #firstPicker></mat-datepicker>
              <mat-error *ngIf="form.get('firstPaymentDate')?.hasError('required')">
                {{ 'first_payment_date_required' | transloco }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="sm:col-span-6">
            <mat-form-field class="w-full fuse-mat-dense">
              <mat-label>{{ 'first_invoice_amount' | transloco }} </mat-label>
              <input matInput formControlName="firstPayment" currencyMask [options]="getOptions()">
              <mat-error *ngIf="form.get('firstPayment')?.hasError('required')">
                {{ 'first_invoice_amount_required' | transloco }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="grid grid-cols-1 gap-x-4 gap-y-1 sm:grid-cols-12">

          <div class="sm:col-span-6">
            <mat-form-field class="w-full fuse-mat-dense">
              <mat-label>{{ 'due_date'| transloco }} </mat-label>
              <mat-select [value]="30" formControlName="recurrentPaymentDay">
                <ng-container *ngFor="let day of monthDays">
                  <mat-option [value]="day">{{day}}</mat-option>
                </ng-container>
              </mat-select>
              <mat-error *ngIf="form.get('recurrentPaymentDay')?.hasError('required')">
                {{ 'due_date_required' | transloco }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="sm:col-span-6">
            <mat-form-field class="w-full fuse-mat-dense">
              <mat-label>{{ 'recurrency_amount' | transloco }} </mat-label>
              <input matInput formControlName="recurrentPayment" currencyMask [options]="getOptions()">
              <mat-error *ngIf="form.get('recurrentPayment')?.hasError('required')">
                {{ 'recurrency_amount_required' | transloco }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="sm:col-span-4">
            <mat-form-field class="w-full fuse-mat-dense">
              <mat-label>{{ 'duration_label' | transloco }} </mat-label>
              <mat-select [value]="1" formControlName="duration">
                <mat-option [value]="1">{{ '1_month' | transloco }}</mat-option>
                <mat-option [value]="2">2 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="3">3 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="4">4 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="5">5 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="6">6 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="7">7 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="8">8 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="9">9 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="10">10 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="11">11 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="12">12 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="13">13 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="14">14 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="15">15 {{ 'months' | transloco }}</mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('duration')?.hasError('required')">
                {{ 'duration_required' | transloco }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="sm:col-span-4">
            <mat-form-field class="w-full fuse-mat-dense">
              <mat-label>{{ 'paid_months' | transloco }} </mat-label>
              <mat-select [value]="1" formControlName="paidMonths">
                <mat-option [value]="1">{{ '1_month' | transloco }}</mat-option>
                <mat-option [value]="2">2 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="3">3 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="4">4 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="5">5 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="6">6 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="7">7 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="8">8 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="9">9 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="10">10 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="11">11 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="12">12 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="13">13 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="14">14 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="15">15 {{ 'months' | transloco }}</mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('duration')?.hasError('required')">
                {{ 'duration_required' | transloco }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="sm:col-span-4">
            <mat-form-field class="w-full fuse-mat-dense">
              <mat-label>{{ 'free_months' | transloco }} </mat-label>
              <mat-select [value]="1" formControlName="freeMonths">
                <mat-option [value]="0">{{ '0_month' | transloco }}</mat-option>
                <mat-option [value]="1">{{ '1_month' | transloco }}</mat-option>
                <mat-option [value]="2">2 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="3">3 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="4">4 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="5">5 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="6">6 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="7">7 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="8">8 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="9">9 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="10">10 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="11">11 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="12">12 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="13">13 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="14">14 {{ 'months' | transloco }}</mat-option>
                <mat-option [value]="15">15 {{ 'months' | transloco }}</mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('duration')?.hasError('required')">
                {{ 'duration_required' | transloco }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
