<div class="flex justify-center p-2 h-full w-full rounded-xl bg-gray-100 border-dashed border-2 border-gray-300 cursor-pointer text-center"
    appDragAndDrop (files)="filesEvent($event)" (click)="fileInput.click()">
    <ng-container *ngFor="let file of files" >
        <div class="w-auto h-auto max-w-full max-h-full m-auto">
            {{file.file.name}}
        </div>
    </ng-container>

    <div *ngIf="!files?.length" class="w-auto h-auto max-w-full max-h-full m-auto">
        <span class="text-lg text-center">{{ 'drop_file' | transloco }}</span>
    </div>

    <input #fileInput type="file"  hidden (change)="doFileInput($event)" multiple="multiple" [accept]="accept">
</div>
