import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, of, tap } from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';
import { defaultNavigation } from './defaultNavigation';
import { compactNavigation } from './compactNavigation';
import { futuristicNavigation } from './futuristicNavigation';
import { horizontalNavigation } from './horizontalNavigation';
import { PermissionService } from 'app/shared/services/permission.service';
import { FuseNavigationItem } from '@fuse/components/navigation';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    constructor(private _httpClient: HttpClient, private readonly permissionService: PermissionService) {
    }

    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    verifyPermission(items: FuseNavigationItem[]): FuseNavigationItem[] {
        for(var item of items){
            if(item.type == 'group'){
                item.children = this.verifyPermission(item.children);
            }
        }

        return items.filter(x => this.permissionService.hasPermissions(x.permission))
    }

    get(): Observable<Navigation> {
        return of(
            <Navigation>{
                default: this.verifyPermission(defaultNavigation),
                compact: compactNavigation,
                futuristic: futuristicNavigation,
                horizontal: horizontalNavigation
            }
        ).pipe(
            tap((navigation) => {
                this._navigation.next(navigation);
            })
        )
    }
}
