import { FuseNavigationItem } from "@fuse/components/navigation";

export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'Anúncios',
        title: 'Anúncios',
        type : 'basic',
        icon : 'mat_outline:ads_click',
        link : '/advertisement',
        tooltip: 'Anúncios'
    }
];
