import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { filter } from 'rxjs';
import { CurrencyResponse, ContractModelListResponse, PaymentType, CurrencyClient, ContractClient } from '../../../../../../../../../../api-client';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss']
})
export class ContractComponent {
  @Input() form: FormGroup;
  @Output() currencySelected = new EventEmitter<any>();

  currencies: CurrencyResponse[] = []
  models: ContractModelListResponse[] = [];

  paymentType = PaymentType;

  monthDays = [...Array(32).keys()].slice(1, 31);

  selectedCurrency: any;

  filter = filter;

  constructor(
    private readonly currencyClient: CurrencyClient,
    private readonly contractClient: ContractClient

  ) { }

  ngOnInit(): void {
    this.listCurrencies()
    this.listModels()
  }

  listModels() {
    this.contractClient.listModels().subscribe({
      next: response => {
        this.models = response.items;
      }
    })
  }

  listCurrencies() {
    this.currencyClient.list(null).subscribe({
      next: response => {
        this.currencies = response;
      }
    })
  }

  onCurrencySelected(event) {
    this.selectedCurrency = this.currencies.find(currency => currency.id === event.value);
    this.currencySelected.emit(this.selectedCurrency);
  }

  getOptions() {
    const currencyValue = this.form.get('currencyId')?.value;
    const currency = this.currencies.find(x => x.id == currencyValue);

    if (!currency)
      return { prefix: '$ ', thousands: '.', decimal: ',' };

    return { prefix: `${currency.symbol} `, thousands: '.', decimal: ',' }
  }
}
