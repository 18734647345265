import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FileHandle } from 'app/shared/diretives/drag-and-drop.directive';

@Component({
    selector: 'app-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent {
    @Output() fileSelected: EventEmitter<FileHandle[]> = new EventEmitter<FileHandle[]>;
    @Input() defaultFiles: FileHandle[];
    @Input() accept: string = "*";

    files: FileHandle[];

    constructor(private sanitizer: DomSanitizer) { }

    ngOnInit(): void {
        if (this.defaultFiles) {
            this.defaultFiles.map(x => (<FileHandle>{
                file: null,
                url: x.url,
                id: x.id
            }))
        }
    }

    filesEvent(files: FileHandle[]) {
        if (files.length > 0) {
            this.files = files;

            this.fileSelected.emit(this.files)
        }
    }

    doFileInput(event) {
        if (event.target.files.length > 0) {
            this.files = []
            for (let file of event.target.files) {
                this.files.push(<FileHandle>{
                    file: file,
                    url: this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file))
                })
            }

            this.fileSelected.emit(this.files)
        }
    }
}
