import { FormGroup } from "@angular/forms";

export class FormValidationHelper {
    static triggerValidation(formGroup: FormGroup): void {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            control.markAsTouched();
            control.markAsDirty();
            control.updateValueAndValidity();

            if (control instanceof FormGroup) {
                FormValidationHelper.triggerValidation(control);
            }
        });
    }
}
