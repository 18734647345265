import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { Permission } from '../../../../api-client';

@Injectable({
    providedIn: 'root'
})
export class PermissionService {

    permissions: Permission[];
    type: string;
    constructor() {
        try {
            this.loadPermissions();
        } catch {
            console.error("Permissions Error");
        }
    }

    loadPermissions() {
        const token = localStorage.getItem('accessToken');

        const data = <any>jwt_decode(token)

        this.permissions = <Permission[]>[...JSON.parse(data['permissions'])];
        this.type = data['type']
    }

    hasPermission(permission: Permission): boolean {
        return this.permissions.findIndex(x => x == permission) != -1;
    }

    hasPermissions(permissions: Permission[]): boolean {
        if (permissions && permissions.length) {
            for (const permission of permissions) {
                if (this.hasPermission(permission)) {
                    return true
                }
            }
            return false;
        }
        return true;
    }


    isInstructor(){
      return this.type == 'Instructor';
    }
}
