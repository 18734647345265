import { Injectable } from '@angular/core';
import * as intlTelInput from 'intl-tel-input';
import { CountryOption } from '../../models/country-option';
import { BehaviorSubject } from 'rxjs';

declare global {
    interface Window {
        intlTelInputGlobals: any;
        intlTelInputUtils: any;
    }
}

@Injectable({
    providedIn: 'root',
})
export class IntlTelInputService {
    private _brazilIso: string = 'br';
    private _overwriteMask = {
        'br': '(00) 0000-0000||(00) 00000-0000'
    };
    intlTelInputInstance: any;
    countryOptions: CountryOption[] = [];
    private initSubject = new BehaviorSubject<boolean>(false);
    public initializationStatus$ = this.initSubject.asObservable();

    constructor() { }

    getCountryOptions() {
        return this.countryOptions;
    }

    getTelInputInstance() {
        return this.intlTelInputInstance;
    }

    private getDefaultCountry() {
        return this._brazilIso;
    }

    setCountryForTelInput(iso2: string) {
        if (this.intlTelInputInstance) {
            this.intlTelInputInstance.setCountry(iso2);
        }
    }

    initialize() {
        this.initInstance(this.getDefaultCountry());
    }

    private initInstance(initialCountry: string): void {
        const hiddenInput = document.createElement('input');
        hiddenInput.style.display = 'none';
        document.body.appendChild(hiddenInput);

        const preferredCountries = ['br', 'us', 'es', 'pt'];

        this.intlTelInputInstance = intlTelInput(hiddenInput, {
            initialCountry: initialCountry,
            preferredCountries: preferredCountries,
            allowDropdown: false,
            showFlags: false,
            onlyCountries: [],
            utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js',
        });

        const waitForUtils = () => {
            if (!!window.intlTelInputUtils) {
                const countries = window.intlTelInputGlobals.getCountryData().filter(countryData => countryData.iso2 !== 'ac'); //There is a bug where ac flag does not exist. So removing it.
                const preferredCountryData = countries.filter(country =>
                    preferredCountries.includes(country.iso2)
                );
                const otherCountryData = countries.filter(
                    country => !preferredCountries.includes(country.iso2)
                );

                const preferredCountryOptions = preferredCountryData.map(country => ({
                    value: {
                        flag: `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/${country.iso2}.svg`,
                        iso2: country.iso2,
                        dialCode: country.dialCode,
                        name: country.name,
                    },
                }));
                const otherCountryOptions = otherCountryData.map(country => ({
                    value: {
                        flag: `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/${country.iso2}.svg`,
                        iso2: country.iso2,
                        dialCode: country.dialCode,
                        name: country.name,
                    },
                }));

                this.countryOptions = [...preferredCountryOptions, ...otherCountryOptions];

                this.initSubject.next(true);
            } else {
                setTimeout(waitForUtils, 100);
            }
        };

        waitForUtils();
    }

    getIso2Code(selectorValue: string): string {
        const selectedCountry = this.countryOptions.find(x => `+${x.value.dialCode}` === selectorValue);
        return selectedCountry ? selectedCountry.value.iso2 : this.getDefaultCountry();
    }

    updatePlaceholderAndMask(iso2: string): { placeholder: string, mask: string } {
        this.setCountryForTelInput(iso2);

        const newPlaceholder = window.intlTelInputUtils.getExampleNumber(
            iso2,
            true,
            window.intlTelInputUtils.numberFormat.INTERNATIONAL
        );

        const telInputInstance = this.getTelInputInstance();
        if (telInputInstance && telInputInstance.telInput) {
            telInputInstance.telInput.setAttribute('placeholder', newPlaceholder);
        }

        if (this._overwriteMask.hasOwnProperty(iso2)) {
            return { placeholder: newPlaceholder, mask: this._overwriteMask[iso2] };
        }

        const mask = newPlaceholder.replace(/[0-9]/g, '0');
        return { placeholder: newPlaceholder, mask: mask };
    }
}
