import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FileHandle } from 'app/shared/diretives/drag-and-drop.directive';

@Component({
    selector: 'app-image-uploader',
    templateUrl: './image-uploader.component.html',
    styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit, OnChanges {
    @Output() fileSelected: EventEmitter<File> = new EventEmitter<File>;
    @Input() defaultImageUrl: string;

    file: FileHandle;

    constructor(private sanitizer: DomSanitizer) { }

    ngOnInit(): void {
        if(this.defaultImageUrl) {
            this.file = <FileHandle>{
                file: null,
                url: this.defaultImageUrl
            }
        }
    }

    ngOnChanges(): void {
        if(this.defaultImageUrl) {
            this.file = <FileHandle>{
                file: null,
                url: this.defaultImageUrl
            }
        }
    }

    files(files: FileHandle[]) {
        if (files.length > 0 && files[0].file.type.includes('image')) {
            this.file = files[0];

            this.fileSelected.emit(this.file.file)
        }
    }

    doFileInput(event) {
        if (event.target.files.length > 0 && event.target.files[0].type.includes('image')) {
            const file = event.target.files[0];
            const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));

            this.file = <FileHandle>{
                file: file,
                url: url
            }

            this.fileSelected.emit(this.file.file)
        }
    }
}
