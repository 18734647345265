import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DocumentType } from '../../../../api-client';

export const getVatNumberMask = (documentType: DocumentType) => {

  switch (documentType) {
    case DocumentType.CPF:
      return "000.000.000-00";
    case DocumentType.SSN:
      return "000-00-0000";
    case DocumentType.Cnpj:
      return "00.000.000/0000-00";
    default:
      return "";
  }
}

export const documentTypes = [
  { key: 'other', value: DocumentType.Other },
  { key: 'cpf', value: DocumentType.CPF },
  { key: 'passport', value: DocumentType.Passport },
  { key: 'ssn', value: DocumentType.SSN },
  { key: 'driver-license', value: DocumentType.DriversLicense },
  { key: 'cnpj', value: DocumentType.Cnpj },
];


export const ssnValidator = () => {
  return (control: AbstractControl): ValidationErrors | null => {
    const valid = control.value ? validateSsn(control.value) : false;
    return valid ? null : { invalidSsn: true };
  };
}

export const validateSsn = (ssn: string) => {
  ssn = ssn.replace(/\D/g, '');
  if (ssn.length !== 9) return false;

  return true;
}

export const cnpjValidator = () => {
  return (control: AbstractControl): ValidationErrors | null => {
    const valid = control.value ? validateCnpj(control.value) : false;
    return valid ? null : { invalidCnpj: true };
  };
}

export const validateCnpj = (cnpj: string) => {
  cnpj = cnpj.replace(/\D/g, '');
  if (cnpj.length !== 14) return false;

  return true;
}

export const cpfValidator = () => {
  return (control: AbstractControl): ValidationErrors | null => {
    const valid = control.value ? validateCPF(control.value) : false;
    return valid ? null : { invalidCPF: true };
  };
}

export const validateCPF = (cpf: string) => {
  cpf = cpf.replace(/\D/g, '');
  if (cpf.length !== 11) return false;

  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * (10 - i);
  }
  let remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(cpf.charAt(9))) return false;

  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * (11 - i);
  }
  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) remainder = 0;
  return remainder === parseInt(cpf.charAt(10));
}