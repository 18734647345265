<!-- Button -->
<button
    mat-icon-button
    [matMenuTriggerFor]="languages">
    <ng-container *ngTemplateOutlet="flagImage; context: {$implicit: activeLang}"></ng-container>
</button>

<!-- Language menu -->
<mat-menu
    [xPosition]="'before'"
    #languages="matMenu">
    <ng-container *ngFor="let lang of availableLangs; trackBy: trackByFn">
        <button
            mat-menu-item
            (click)="setActiveLang(lang)">
            <span class="flex items-center">
                <ng-container *ngTemplateOutlet="flagImage; context: {$implicit: lang}"></ng-container>
            </span>
        </button>
    </ng-container>
</mat-menu>

<!-- Flag image template -->
<ng-template
    let-lang
    #flagImage>
    <span class="relative w-6 rounded-sm overflow-hidden" style="margin-top: 0.2em">
        <span class="absolute "></span>
        <mat-icon class="icon-size-6 w-full"
                      [svgIcon]="'flags:' + flagCodes[lang]"></mat-icon>
    </span>
</ng-template>
