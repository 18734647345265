import { NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { flags } from './flags.list'
import { social } from './social.list';
@NgModule()
export class IconsModule {
    /**
     * Constructor
     */
    constructor(
        private _domSanitizer: DomSanitizer,
        private _matIconRegistry: MatIconRegistry
    ) {
        // Register icon sets
        this._matIconRegistry.addSvgIconSet(this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-twotone.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('mat_outline', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-outline.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('mat_solid', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-solid.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('feather', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/feather.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('heroicons_outline', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-outline.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('heroicons_solid', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-solid.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('heroicons_mini', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-mini.svg'));

        for(let flag of flags) {
            this._matIconRegistry.addSvgIconInNamespace('flags', flag, this._domSanitizer.bypassSecurityTrustResourceUrl(`assets/flags/${flag}.svg`), {});
        }

        for(let item of social) {
          this._matIconRegistry.addSvgIconInNamespace('social', item, this._domSanitizer.bypassSecurityTrustResourceUrl(`assets/social/${item}.svg`), {});
      }
    }
}
